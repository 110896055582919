import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PrimengModule } from '../primeng/primeng.module';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { SecurityPipe } from './pipes/security.pipe';
import { HomeComponent } from './components/home/home.component';
// import { ModalCohetoComponent } from './components/modal-coheto/modal-coheto.component';
@NgModule({
  declarations: [
    AppHeaderComponent,
    AppFooterComponent,
    AppMenuComponent,
    SecurityPipe,
    HomeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PrimengModule
  ],
  exports: [
    AppHeaderComponent,
    AppFooterComponent,
    SecurityPipe
  ]
})
export class CoreModule {
}
