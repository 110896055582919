export class User {
  cdsid: string;
  resources: string[];
  dealers: number[];

  constructor(cdsid: string, resources: string[], dealers: number[]) {
    this.cdsid = cdsid;
    this.resources = resources;
    this.dealers = dealers;
  }
}

