// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.local.ts` with `environmentLocal.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  env: 'dev',
  production: false,
  host_authorize: 'https://corpqa.sts.ford.com/',
  host_api: 'https://api.d01e.gcp.ford.com/wwtf-gcp/',
  oauth2_client_id: 'urn:wwtf:clientid:web_site_wwtf:dev',
  oauth2_uri_resource: 'urn:wwtf:resource:web_site_wwtf:dev',
  openidLogout: 'https://corpqa.sts.ford.com/adfs/oauth2/logout',
  openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
  responseType: 'token+id_token',
  securityEnabled: true,
  ch_dealerHome: 'HOME.GENERAL',
  ch_billsEnquiry: 'ENQUIRY.BILLS.GENERAL',
  ch_billsEnquiry_export: 'ENQUIRY.BILLS.EXPORT',
  ch_billsEnquiry_detail: 'ENQUIRY.BILLS.DETAIL',
  ch_suspense: 'ENQUIRY.SUSPENSE.GENERAL',
  ch_remittanceRequest: 'REQUEST.REMITTANCE.GENERAL',
  ch_remittanceEnquiry: 'ENQUIRY.REMITTANCE.GENERAL',
  ch_paymentEnquiry: 'ENQUIRY.PAYMENT.GENERAL',
  ch_paymentEnquiry_detail: 'ENQUIRY.PAYMENT.DETAIL',
  redirect_url: 'https://wwwdev.wwtf.ford.com/',
  // dealerCodes: [179, 78, 1],
  get oauth2_uri_authorize() {
    return this.host_authorize + 'adfs/oauth2/authorize';
  },
  get me_uri() {
    return this.host_api + 'api/v1/me';
  },
  get me_customerSummary() {
    return this.host_api + 'api/v1/customer-summary';
  },
  get me_customerSummaryDealer() {
    return this.host_api + 'api/v1/customer-summary/{dealerCode}';
  },
  get me_billsByType() {
    return this.host_api + 'api/v1/bills-by-type/{dealerCode}';
  },
  get me_bills() {
    return this.host_api + 'api/v1/bills';
  },
  get me_bills_billNumber() {
    return this.host_api + 'api/v1/bills/{billNumber}';
  },
  get me_remittanceBills() {
    return this.host_api + 'api/v1/remittance/bills';
  },
  get me_remittanceBillCancel() {
    return this.host_api + 'api/v1/remittance/cancel/{remittanceId}';
  },
  get me_remittanceBillReject() {
    return this.host_api + 'api/v1/remittance/reject/{remittanceId}';
  },
  get me_remittancePDF(){
    return this.host_api + 'api/v1/remittance';
  },
  get me_remittanceEnquiry() {
    return this.host_api + 'api/v1/remittance';
  },
  get me_suspenseEnquiry() {
    return this.host_api + 'api/v1/suspense';
  },
  get me_remittanceEnquiryDetail() {
    return this.host_api + 'api/v1/remittance/bills/{remittanceId}';
  },
  get me_remittanceDetailAtValueDate() {
    return this.host_api + 'api/v1/remittance/bills/actual/{remittanceId}';
  },
  get active_export() {
    return this.host_api + 'api/v1/bills/export/active';
  },
  get paid_export() {
    return this.host_api + 'api/v1/bills/export/paid';
  },
  get customer_uri() {
    return this.host_api + 'api/v1/customer/{dealerCode}';
  },
  get customers_all_uri() {
    return this.host_api + 'api/v1/customer';
  },
  get customers_regulars_dealer() {
    return this.host_api + 'api/v1/customer/regular/{dealerCode}';
  },
  get me_paymentsEnquiry() {
    return this.host_api + 'api/v1/payments';
  },
  get me_paymentsEnquiryDetail() {
    return this.host_api + 'api/v1/payments/{paymentId}';
  },
  get me_bank_currency() {
    return this.host_api + 'api/v1/bank/{currency}';
  },
  get me_limit_writeoff() {
    return this.host_api + 'api/v1/user';
  },
  get me_calculate_payment_allocation() {
    return this.host_api + 'api/v1/payment/allocation/calculate';
  },
  get me_process_payment_allocation() {
    return this.host_api + 'api/v1/payment/allocation/process';
  },
  get me_recalculate_payment_allocation() {
    return this.host_api + 'api/v1/payment/allocation/recalculate';
  },
  get me_contextual_help() {
    return this.host_api + 'api/v1/contextualhelp';
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
