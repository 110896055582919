import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';

/** This component handles redirecting users to the auth provider */
@Component({
  template: `<h3>Redirecting to login server</h3>`
})
export class LoginComponent implements OnInit {
  endPoint: string;

  constructor() {
    /** the configured URL endpoint to send the user's browser to for token negotiation */
    this.endPoint = environment.oauth2_uri_authorize +
        '?client_id=' + environment.oauth2_client_id +
        '&resource=' + environment.oauth2_uri_resource +
        '&response_type=token' +
        '&redirect_uri=' + environment.redirect_url;
  }

  /** once the component is up and running, redirect the user to the auth provider */
  ngOnInit() {
    // can't use the Angular location service here, as it always constructs against the app base href
    window.location.replace(this.endPoint);
  }
}
