import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";
import { tap } from "rxjs/operators";
import { Lang } from "src/app/interfaces/Lang";
import { UserIdService } from "src/app/services/user-id/user-id.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"],
})
export class AppHeaderComponent implements OnInit {
  userId: string;
  items: MenuItem[];
  langs: Lang[];
  selectedLang: Lang;

  constructor(private userIdService: UserIdService, private translate: TranslateService) {
    this.langs = [
      { name: "languages.en", code: "en" },
      { name: "languages.es", code: "es" },
      { name: "languages.pt", code: "pt" },
      { name: "languages.fr", code: "fr" },
    ];
    this.translate.onLangChange.subscribe((event) => {
      const translations = event.translations;
      this.userIdService
        .getUser()
        .pipe(
          tap((user) => {
            this.userId = user.cdsid;
            this.items = [
              {
                label: translations.menu.mainMenu,
                items: [
                  {
                    label: translations.menu.customers_enquiry,
                    icon: "pi pi-fw pi-cog",
                    url: "#/home/backoffice",
                    visible: user.resources.includes("ROLE_BACK_OFFICE"),
                  },
                  {
                    label: translations.menu.dealer_home,
                    icon: "pi pi-fw pi-users",
                    url: "#/home/dealer",
                    visible: user.resources.includes("ROLE_DEALER"),
                  },
                  {
                    label: translations.menu.bills_inquiry,
                    icon: "pi pi-fw pi-briefcase",
                    url: "#/bills/bills-enquiry",
                    visible: user.resources.includes("ROLE_BACK_OFFICE") || user.resources.includes("ROLE_DEALER"),
                  },
                  {
                    label: translations.menu.suspense_enquiry,
                    icon: "pi pi-fw pi-tag",
                    url: "#/suspense/suspense-enquiry",
                    visible: user.resources.includes("ROLE_BACK_OFFICE") || user.resources.includes("ROLE_DEALER"),
                  },
                  {
                    label: translations.menu.confirmation_enquiry,
                    icon: "pi pi-fw pi-check-circle",
                    disabled: true,
                    visible: false,
                  },
                  {
                    label: translations.menu.credit_limit_enquiry,
                    icon: "pi pi-fw pi-table",
                    disabled: true,
                    visible: false,
                  },
                  {
                    label: translations.menu.remittance_request,
                    icon: "pi pi-fw pi-list",
                    url: "#/payments/remittance-request",
                    visible: user.resources.includes("ROLE_BACK_OFFICE") || user.resources.includes("ROLE_DEALER"),
                  },
                  {
                    label: translations.menu.remittance_enquiry,
                    icon: "pi pi-fw pi-table",
                    url: "#/payments/remittance-enquiry",
                    visible: user.resources.includes("ROLE_BACK_OFFICE") || user.resources.includes("ROLE_DEALER"),
                  },
                  {
                    label: translations.menu.messages,
                    icon: "pi pi-fw pi-comment",
                    disabled: true,
                    visible: false,
                  },
                  {
                    label: translations.menu.payment_enquiry,
                    icon: "pi pi-fw pi-money-bill",
                    url: "#/payments/payment-enquiry",
                    visible: user.resources.includes("ROLE_BACK_OFFICE") || user.resources.includes("ROLE_DEALER"),
                  },
                ],
              },
            ];
          })
        )
        .subscribe((user) => user);
    });
  }

  ngOnInit() {
    this.selectedLang = this.langs.find((lang) => lang.code === this.translate.currentLang);
  }

  changeLanguage() {
    this.translate.use(this.selectedLang.code);
  }

  logout() {
    this.prepareFrame(environment.openidLogout);
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace(this.getAdfsUrl());
  }

  private prepareFrame = (url) => {
    const ifrm = document.createElement("iframe");
    ifrm.setAttribute("src", url);
    ifrm.style.display = "none";
    document.body.appendChild(ifrm);
  };

  private getAdfsUrl() {
    return `${environment.openidUrl}?client_id=${environment.oauth2_client_id}&response_type=${
      environment.responseType
    }&redirect_uri=${encodeURIComponent(environment.redirect_url)}&resource=${environment.oauth2_uri_resource}`;
  }
}

