<div class="p-grid toolbar" role="banner">
  <div id="left" class="p-col-1">
    <app-menu [items]="items"></app-menu>
  </div>
  <div id="main" class="p-col-3">
    <a href="#/"><div id="logo"></div></a>
    <span id="title">{{ "title" | translate }}</span>
  </div>
  <div id="right" class="p-col-6">
    <p-dropdown
      id="listLang"
      styleClass="text-left"
      [options]="langs"
      [(ngModel)]="selectedLang"
      optionLabel="name"
      (onChange)="changeLanguage()"
    >
      <ng-template let-item pTemplate="selectedItem">
        <img
          class="item-selected"
          height="20px"
          style="vertical-align: middle"
          src="./assets/images/flags/{{ item.value.code }}.png"
          height="25px"
        />
        <span class="label-selected" style="width: 60px">{{ item.value.code | uppercase }}</span>
      </ng-template>
      <ng-template let-lang pTemplate="item">
        <div style="width: 100%; display: flex">
          <img
            class="item-list"
            height="20px"
            style="vertical-align: middle"
            src="./assets/images/flags/{{ lang.value.code }}.png"
            height="20px"
          />
          <span style="width: 60px">{{ lang.value.name | translate }}</span>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div id="profile" class="p-col-1">
    <i class="pi pi-user"></i><span id="name">{{ userId || 'Not Logged In' }}</span>
  </div>

  <div class="p-col-1 logout">
    <a role="button" (click)="logout()" *ngIf="userId">
      <i title="Logout" class="pi pi-sign-out"></i>
    </a>
  </div>
</div>
