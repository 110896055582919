import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, share, tap } from 'rxjs/operators';
import { ContextualHelp } from 'src/app/model/contextualHelp';
import { environment } from '../../../environments/environment';
import { BaseResponse } from '../../model/base-response';
import { User } from '../../model/user';
import { CustomHttpParamEncoder } from '../../services/util/custom-http-param-encoder';


const CACHE_SIZE = 1;
const REFRESH_INTERVAL = 300000;

/** This class is a service to handle holding and distributing user ID to be displayed
 */
@Injectable({
  providedIn: 'root'
})
export class UserIdService {

  private user: BehaviorSubject<User> = new BehaviorSubject<User>(new User(null, [], []));
  private valid = false;
  meObservable: Observable<any>;
  /** creates a new instance of the UserIdService
   */
  constructor(private http: HttpClient, private messageService: MessageService) {
    // if (sessionStorage.getItem('userId')) {
    //   this.setUser(new User(sessionStorage.getItem('userId'), JSON.parse(sessionStorage.getItem('resources'))));
    // }
  }

  /** Stores user object
   *
   * @param userObj the user data to be stored on in the userObj property of this service
   */
  // setUser(userObj: User) {
  //   this.user.next(userObj);
  // }

  getUser(): Observable<User> {
    if (!this.valid && sessionStorage.getItem('encodedAccessToken')) {
      // Set up timer that ticks every X milliseconds
      // const timer$ = timer(0, REFRESH_INTERVAL);
      return this.getMe().pipe(
          tap(user => this.user.next(user)),
          tap(_ => this.valid = true),
          catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.valid = true
              }
            }
            return of(err);
          })
      );
      // this.cache$ = timer$.pipe(
      //     switchMap(_ => this.getMe()),
      //     shareReplay(CACHE_SIZE)
      // );
    }

    return this.user;
  }

  clearUser(): void {
    this.valid = false;
    this.user.next(new User(null, [], []));
  }

  //
  //
  // private isTokenAboutToExpire(): boolean {
  //   const epoch = Math.trunc(new Date().getTime() / 1000);
  //   let expEpoch = null;
  //
  //   if (sessionStorage.getItem('tokenExp') && sessionStorage.getItem('tokenExp') !== 'null') {
  //     expEpoch = parseInt(sessionStorage.getItem('tokenExp'), 10);
  //     return (epoch >= (expEpoch - (SESSION_WARNING_MINUTES * 60)));
  //   } else {
  //     return true;
  //   }
  // }

  private getMe(): Observable<User> {
    if (this.meObservable) {
      return this.meObservable;
    } else {
      this.meObservable = this.http.get<BaseResponse<User>>(environment.me_uri)
          .pipe(
              map(response => response.result),
              share()
          );
      return this.meObservable;
    }
  }

  getContentContextualHelp(reference: string): Observable<ContextualHelp> {
    const params = new HttpParams({encoder: new CustomHttpParamEncoder()})
        .set('reference', reference)
    return this.http.get<BaseResponse<ContextualHelp>>( environment.me_contextual_help, {params} )
        .pipe(map(res => res.result));
  }
}
