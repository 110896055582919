import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Location} from '@angular/common';
import {UserIdService} from '../services/user-id/user-id.service';
import {Observable, of} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {User} from '../model/user';
import {MessageService} from 'primeng';


const SESSION_WARNING_MINUTES = 5;

/** This guard is used in the oauth callback flow, consuming the URL before the Angular router can.
 * The URL built by the auth provider is technically invalid (no ? for the queryParams), so this guard
 * needs to consume the URL before Angular's router (which would fail to parse it).
 */
@Injectable()
export class UrlConsumerService implements CanActivate {
  /** Creates an instance of the UrlConsumerService
   *
   * @param router route instance for current routing params
   * @param location the angular location service for interacting with the browser location object
   * @param idService the angular service for handling user ID
   * @param meService service calling /me endpoint
   */
  constructor(private router: Router, private location: Location, private idService: UserIdService, private messageService: MessageService) {
  }

  /** the actual guard fuction. Parses the queryString and stores the params in sessionStorage.
   * Redirects the user to the default route, or to the route that was stored before the auth redirect.
   *
   * @param route the snapshot of the current ActivatedRoute
   * @param state the snapshot of the current RouterState
   * @returns whether route can be activated or not
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const queryParamsObj = this.getQueryParams();
    
    if (queryParamsObj && queryParamsObj['access_token'] != null) {
      // token is part 2 of a JWT (index 1)
      const accessToken = atob(queryParamsObj['access_token'].split('.')[1]);
      const jsonToken = JSON.parse(accessToken);
      // jsonToken.iat = jsonToken.iat;
      //jsonToken.exp = jsonToken.iat + 380;
      sessionStorage.setItem('strAccessToken', accessToken);
      sessionStorage.setItem('encodedAccessToken', queryParamsObj['access_token']);

      sessionStorage.setItem('tokenIssue', jsonToken.iat);
      sessionStorage.setItem('tokenExp', jsonToken.exp);
      // sessionStorage.setItem('userId', jsonToken.CommonName);

      // sessionStorage.setItem('dealerCodes', JSON.stringify(environment.dealerCodes));

      const currentEpochSeconds = Math.trunc(new Date().getTime() / 1000);

      let expirationEpochSeconds = null;
      if (sessionStorage.getItem('tokenExp') && sessionStorage.getItem('tokenExp') !== 'null') {
        expirationEpochSeconds = parseInt(sessionStorage.getItem('tokenExp'), 10);
      }
      const expiresInSeconds = expirationEpochSeconds - currentEpochSeconds;
      const warningInSeconds = expiresInSeconds - (SESSION_WARNING_MINUTES * 60);

      setTimeout(() => {
            this.messageService.clear();
            this.messageService.add({key: 'warning', sticky: true, severity: 'info', closable: true});
          },
          warningInSeconds * 1000
      );

      setTimeout(() => {
            this.messageService.clear();
            this.messageService.add({key: 'bye', sticky: true, severity: 'error', closable: false});
          },
          expiresInSeconds * 1000
      );

      return this.idService.getUser().pipe(
          map((user) => {
            // sessionStorage.setItem('resources', JSON.stringify(user.resources));
            // Redirect to originally requested URL
            return this.navigateTo(user);
          })
      );

    } else {
      this.router.navigate(['/error/401']);
      return of(false);
    }


  }

  navigateTo(user: User): boolean {
    const lastUrl = sessionStorage.getItem('redirectURL');
    if (!lastUrl || lastUrl === '/login' || lastUrl.includes('error')) {
      this.router.navigate(['/']);
      return true;
      // if (!user.resources || !user.resources.includes('ROLE_USER')) {
      //   this.router.navigate(['/error/401']);
      //   return false;
      // } else if (user.resources && user.resources.includes('ROLE_BACK_OFFICE')) {
      //   this.router.navigate(['/home/backoffice']);
      //   return true;
      // } else if (user.resources && user.resources.includes('ROLE_DEALER')) {
      //   this.router.navigate(['/home/dealer']);
      //   return true;
      // } else {
      //   this.router.navigate(['/error/401']);
      //   return false;
      // }

    } else {
      this.router.navigate([sessionStorage.getItem('redirectURL')]);
      return true;
    }
  }


  /* Parses the technically malformed queryString to pick off the token and associated properties.
   @returns The queryString params in Object format, or null if the string was invalid. */

  getQueryParams() {
    let queryString = new Map();
    //if (this.location.path(true)?.startsWith('access_token')) {
      const query = this.location.path(true);
      const vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        let key = decodeURIComponent(pair[0]);
        let value = decodeURIComponent(pair[1] || '');

        // If first entry with this name
        if (!queryString.has(key)) {
            //if (allowed.has(key)) {
              queryString.set(key, value);
            //}
        } else {
          var existingValue = queryString.get(key);
          if (Array.isArray(existingValue)) {
            existingValue.push(value);
            queryString.set(key, existingValue);
        } else {
          queryString.set(key, [existingValue, value]);
        }
        }
      }


      return Object.fromEntries(queryString)
      //const queryString = this.location.path(true)?? '';

      // URLSearchParams should be the solution here. it's not working. so we did it manually
      // const paramArray = queryString.split('&');
      // const queryParamsObj = {};

      // for (const param of paramArray) {
      //   // we can't use a simple split() call here as base64 allows for = padding
      //   const i = param.indexOf('=');
      //   const splitArray = [param.slice(0, i), param.slice(i + 1)];
      //   queryParamsObj[splitArray[0]] = splitArray[1];
      // }
      // return queryParamsObj;
    // } else {
    //   return null;
    // }
  }
}

