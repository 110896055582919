<app-header></app-header>
<div class="p-grid">
    <div id="content" class="p-col-10 p-offset-1">
      <router-outlet></router-outlet>
    </div>
</div>
<app-footer></app-footer>

<!--<button *ngIf="!visibleMessages" id="messages" pButton type="button" (click)="visibleMessages = true" pTooltip="{{'menu.messages'|translate}}" tooltipPosition="top">-->
<!--  <i class="pi pi-envelope"></i>-->
<!--  <span class="label">{{messagesList.length}}</span>-->
<!--</button>-->
<p-sidebar [(visible)]="visibleMessages" position="right" [baseZIndex]="10000">
  <div class="p-20 p-t-50">
    <h1 style="font-weight:normal"><i class="pi pi-envelope"></i> {{'menu.messages'|translate}} ({{messagesList.length}})</h1>
    <div class="message-box" *ngFor="let message of messagesList">
      <p class="message-corner"></p>
      <p class="message-date">{{message.date}}</p>
      <p class="message-from">{{message.from}}</p>
      <p class="message-text"><em>{{message.text}}</em></p>
    </div>
  </div>
</p-sidebar>

<p-toast position="center" key="warning" [modal]="true">
  <ng-template let-message pTemplate="message">
    <div class="text-center p-b-10">
      <i class="pi pi-exclamation-triangle m-t-10" style="font-size: 3em"></i>
      <h3 style="color: black">{{ 'messages.logout.warning'|translate }}</h3>
      <p-button type="button" (click)="navigateToLogIn()" label="Reload"></p-button>
    </div>
  </ng-template>
</p-toast>

<p-toast position="center" key="bye" [modal]="true">
  <ng-template let-message pTemplate="message">
    <div class="text-center p-b-10">
      <i class="pi pi-exclamation-triangle m-t-10" style="font-size: 3em"></i>
      <h3 style="color: black">{{ 'messages.logout.error'|translate }}</h3>
      <p-button type="button" (click)="navigateToLogIn()" label="Log in"></p-button>
    </div>
  </ng-template>
</p-toast>

<p-toast position="center" key="error" [modal]="true" class="toast-error">
  <ng-template let-message pTemplate="message">
    <div class="text-center p-b-10">
      <i class="pi pi-exclamation-triangle m-t-10" style="font-size: 3em"></i>
      <h3 style="color: red">{{ message.summary }}</h3>
      <h4>Please contact the IT team with the following information.</h4>
      <p>{{ message.detail }}</p>
      <h6>{{ message.data }}</h6>
    </div>
  </ng-template>
</p-toast>
