import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BaseResponse} from '../../model/base-response';
import {User} from '../../model/user';
import {Observable} from 'rxjs';
import {delay, map, tap} from 'rxjs/operators';
import {UtilService} from '../util/util.service';
import {Customer} from '../../model/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient, private utilService: UtilService) {
  }

  getCustomerName(dealerCode: number): Observable<string> {
    return this.getCustomer(dealerCode).pipe(
        map(customer => customer.dealerName)
    );
  }
  getCustomersList(){
    return this.http.get<BaseResponse<any>>(environment.customers_all_uri)
        .pipe(
            map(response => response.result)
        );
  }
  getCustomer(dealerCode: number): Observable<Customer> {
    return this.http.get<BaseResponse<Customer>>( this.utilService.getParamURL(environment.customer_uri, {dealerCode}) )
        .pipe(
            map(response => response.result)
        );
  }
}
