import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: string;
  visibleMessages: boolean;
  messagesList: any[];

  constructor(private translate: TranslateService, private router: Router) {
    this.title = 'WWTF';
    this.configureLanguage();
  }

  configureLanguage() {
    this.translate.addLangs(['en', 'es', 'fr', 'pt']);
    this.translate.setDefaultLang('en');
    let browserLang = this.translate.getBrowserLang();
    if (browserLang === undefined || !this.translate.getLangs().includes(browserLang)) {
      browserLang = this.translate.getDefaultLang();
    }
    this.translate.use(browserLang);

    this.visibleMessages = false;
    this.messagesList = [
      {
        "date": "2019-08-16 01:11:57",
        "from": "Lorrie Black",
        "text": "esse nulla aute exercitation esse proident commodo culpa ullamco nulla"
      },
      {
        "date": "2014-07-05 02:07:39",
        "from": "Monique Dean",
        "text": "nulla commodo id minim consectetur sit labore deserunt velit et"
      },
      {
        "date": "2016-11-01 06:22:46",
        "from": "Lynn Savage",
        "text": "nisi quis tempor sint ut ad aliquip proident commodo adipisicing"
      },
      {
        "date": "2019-07-20 02:23:39",
        "from": "Duke Moss",
        "text": "nulla commodo commodo non reprehenderit labore est in labore est"
      },
      {
        "date": "2016-05-20 04:00:08",
        "from": "Mueller Donovan",
        "text": "adipisicing et culpa deserunt aute officia tempor pariatur enim officia"
      },
      {
        "date": "2016-12-07 09:40:37",
        "from": "Wong Pruitt",
        "text": "occaecat tempor Lorem dolore labore irure cillum aliqua nulla cillum"
      }
    ];
  }

  navigateToLogIn() {
    this.router.navigate(['/login']);
  }

}
