import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {Injectable} from '@angular/core';
/** This class is an implementation of an HttpInterceptor
 */
 @Injectable({
  providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {
  //public messageService: MessageService = new MessageService();
  constructor( private messageService: MessageService) {
  }
  /** This method is required by the fact that this class implements HttpInterceptor.
   * It accepts (intercepts) HttpRequests and adds the headers required by API's for this application.
   *
   * @param request the HttpRequest object
   * @param next the Http handling object
   * @returns the Observable representing the next HttpEvent
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    request = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + sessionStorage.getItem('encodedAccessToken')
      }
    });
    // return next.handle(request);
    return next.handle(request)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        let errorCode = '';
        let errorMsg = '';
        console.log(error);
        if(!error.url.includes('contextualhelp')) {
          if (error.error instanceof ErrorEvent) {
            console.log('this is client side error');
            errorCode = `Error: ${error.error.message}`;
          }
          else {
            console.log('this is server side error');
            errorCode = `Error Code: ${error.status}.`;
            errorMsg = `${error.message}`
          }
          console.log(errorMsg);
          this.messageService.clear('error');
          let actualDateLocation = new Date();
          this.messageService.add({key: 'error', sticky: true, severity: 'error', closable: true, summary:errorCode, detail:errorMsg, data: actualDateLocation});
          return throwError(errorMsg);
        } else {
          return throwError(errorMsg);
        }
      })
    )
  }
}
