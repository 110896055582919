import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {UserIdService} from '../../../services/user-id/user-id.service';
import {map} from 'rxjs/operators';

@Component({
  template: `<h3>Redirecting to home</h3>`
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private idService: UserIdService) {
    this.idService.getUser().subscribe(
        (user) => {
          if (!user.resources || !user.resources.includes('ROLE_USER')) {
            this.router.navigate(['/error/401']);
          } else if (user.resources && user.resources.includes('ROLE_BACK_OFFICE')) {
            this.router.navigate(['/home/backoffice']);
          } else if (user.resources && user.resources.includes('ROLE_DEALER')) {
            this.router.navigate(['/home/dealer']);
          } else {
            this.router.navigate(['/error/401']);
          }
        }
    );
  }

  ngOnInit(): void {

  }

}
