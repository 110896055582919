import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Currencies} from './enums';
import {rejects} from 'assert';
import {Currency} from '../../interfaces/Currency';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private http: HttpClient) { }

  stringToDate(s: string) {
    const ss = s.split("/");
    const ds = new Date(ss[1]+"/"+ss[0]+"/"+ss[2]);
    return ds;
  }

  formatDMYDate(date: Date) {
    const fd = ('0' + date.getDate()).slice(-2) + '/'
      + ('0' + (date.getMonth() + 1)).slice(-2) + '/'
      + date.getFullYear();
    return fd;
  }

  formatDMYHISDate(date: Date) {
    const fd = ('0' + date.getDate()).slice(-2) + '/'
      + ('0' + (date.getMonth() + 1)).slice(-2) + '/'
      + date.getFullYear()
      + ' '
      + ('0' + (date.getHours() + 1)).slice(-2) + ':'
      + ('0' + (date.getMinutes() + 1)).slice(-2) + ':'
      + ('0' + (date.getSeconds() + 1)).slice(-2);
    return fd;
  }

  formatYMD(date: Date) {
    const fd = date.getFullYear() + '-'
        + ('0' + (date.getMonth() + 1)).slice(-2) + '-'
        + ('0' + date.getDate()).slice(-2);
    return fd;
  }

  checkOnDate(date: Date) {
    const od = (this.calcDiffDays(new Date(), date) >= 0);
    return od;
  }

  calcDiffDays(fromDate: Date, toDate: Date) {
    const diff = Math.ceil((toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24));
    return diff;
  }

  getStringAmountCurrencies(cAmount: Currency) {
    const currencies = Object.keys(cAmount);
    if (currencies.length === 0) {
      return '-';
    }
    let s = '';
    currencies.forEach((value, index) => {
      const t = (cAmount[value] > 0) ? 'class=\"color-status danger\"' : '';
      s += '<span ' + t + '>' + this.commafyNumber(Math.round((cAmount[value] * 100) / 100).toFixed(2)) + ' ' + value + '</span>';
      if (index < currencies.length - 1) {
        s += ' | ';
      }
    });
    return s;
  }

  commafyNumber( strNum: string ) {
    const str = strNum.split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  }

  getParamURL( url: string, params: object ) {
    const listParams = Object.keys(params);
    let paramURL = url;
    listParams.forEach((p => {
      paramURL = paramURL.replace('{' + p + '}', params[p]);
    }));
    return paramURL;
  }
  formatExcelHeaders(headers){
    const finalHeaders = [];
    if (headers) {
      const headersArray = Object.keys(headers);
      headersArray.forEach(header=>{
        let formattedHeader = header.replace(/([A-Z])/g, ' $1').trim();
        formattedHeader = formattedHeader.charAt(0).toUpperCase() + formattedHeader.slice(1);
        finalHeaders.push(formattedHeader);
      });
      return finalHeaders
    }
  }
  formatHeaders(header){
    let formattedHeader = header.replace(/([A-Z])/g, ' $1').trim();
    formattedHeader = formattedHeader.charAt(0).toUpperCase() + formattedHeader.slice(1);
    return formattedHeader
  }
  getMultipleFilter(list: any[]): string {
    let strList = '';
    const listUnique = list.filter((item, index) => list.indexOf(item) >= index);
    listUnique.forEach((i) => {
      strList += (strList.length) ? ';' : '';
      strList += i.code;
    });
    return strList;
  }
  formatDate (input) {
    if (input !== null){
      const datePart = input.match(/\d+/g),
          year = datePart[0], // get only two digits
          month = datePart[1], day = datePart[2];
      return day+'/'+month+'/'+year;
    }
  }
  // adds comma in miles, dot in decimals & 2 decimals always
  numCommaDot(num){
    if(num !== null){
      return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
  formatInterestDecimals(num) {
    if(num !== null){
      return num.toFixed(6).toString().replace(/\B(?=(\d{6})+(?!\d))/g, ',');
    }
  }
}
