import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UrlConsumerService } from './oauth/url-consumer.service';
import { OAuthCallbackComponent } from './oauth/oauth-callback.component';
import { LoginComponent } from './oauth/login.component';
import { AuthGuardService } from './oauth/auth-guard.service';
import {HomeComponent} from './core/components/home/home.component';

const routes: Routes = [
  {
    path: 'access_token',
    component: OAuthCallbackComponent,
    canActivate: [UrlConsumerService]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'bills',
    loadChildren: () => import('./container/bills/bills.module').then(m => m.BillsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'suspense',
    loadChildren: () => import('./container/suspense/suspense.module').then(m => m.SuspenseModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'home/backoffice',
    loadChildren: () => import('./container/home/backoffice/backoffice.module').then(m => m.BackofficeModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'home/dealer',
    loadChildren: () => import('./container/home/dealer/dealer.module').then(m => m.DealerModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'payments',
    loadChildren: () => import('./container/payments/payments.module').then(m => m.PaymentsModule),
    canActivate: [AuthGuardService]
  },
  { path: 'error', loadChildren: () => import('./container/error/error.module').then(m => m.ErrorModule) },
  { path: '', component: HomeComponent,canActivate: [AuthGuardService] },
  { path: '**', redirectTo: '/error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
