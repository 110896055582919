import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { MenuModule } from 'primeng/menu';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { ChartModule } from 'primeng/chart';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { SliderModule } from 'primeng/slider';
import { SidebarModule } from 'primeng/sidebar';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { CarouselModule } from 'primeng/carousel';
import { TabViewModule } from 'primeng/tabview';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { CardModule } from 'primeng/card';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OverlayModule } from "@angular/cdk/overlay";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AccordionModule,
    CalendarModule,
    MenuModule,
    BreadcrumbModule,
    TableModule,
    DropdownModule,
    SelectButtonModule,
    TooltipModule,
    ChartModule,
    ScrollPanelModule,
    VirtualScrollerModule,
    SidebarModule,
    ButtonModule,
    InputMaskModule,
    SliderModule,
    CheckboxModule,
    RadioButtonModule,
    MultiSelectModule,
    CarouselModule,
    TabViewModule,
    InputNumberModule,
    ToastModule,
    ProgressBarModule,
    CardModule,
    MessageModule,
    OverlayPanelModule,
    OverlayModule,
    ProgressSpinnerModule
  ],
  exports: [
    CalendarModule,
    AccordionModule,
    MenuModule,
    BreadcrumbModule,
    TableModule,
    DropdownModule,
    SelectButtonModule,
    TooltipModule,
    ChartModule,
    ScrollPanelModule,
    VirtualScrollerModule,
    SidebarModule,
    ButtonModule,
    InputMaskModule,
    SliderModule,
    CheckboxModule,
    RadioButtonModule,
    MultiSelectModule,
    CarouselModule,
    TabViewModule,
    ToastModule,
    InputNumberModule,
    ProgressBarModule,
    CardModule,
    MessageModule,
    OverlayPanelModule,
    OverlayModule,
    ProgressSpinnerModule
  ],
  providers: [MessageService]
})
export class PrimengModule {
}
