import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'security'
})
export class SecurityPipe implements PipeTransform {

  transform(value: any, allowed: boolean): any {
    return allowed ? value : null;
  }

}
